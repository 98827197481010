<template>
  <keep-alive>
    <router-view v-if="$route.meta.subPage" />
    <a-card class="card" :bordered="false" v-else>
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="GLOBAL.queryRowGutter">
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="租户简称">
                <a-select
                  showSearch
                  allowClear
                  placeholder="请选择租户"
                  style="width: 100%"
                  v-model="queryParam.tenant"
                  :filter-option="filterOption"
                >
                  <a-select-option v-for="op in tenantOps" :key="op.id">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="业务编号">
                <a-input v-model="queryParam.serialNum" allowClear></a-input>
              </a-form-item>
            </a-col>
            <a-col :xs="6">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
        <a-button type="danger" @click="handleDelete('all')" :disabled="!this.selectedRowKeys.length">删除</a-button>
      </div>

      <s-table
        ref="table"
        size="default"
        rowKey="id"
        :columns="columns"
        :data="loadData"
        :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
        @dblclick="handleEdit"
      >
        <span slot="action" slot-scope="text, record, index">
          <template>
            <a @click="handleEdit(record, index)">编辑</a>
            <a-divider type="vertical" />
            <a @click="handleDelete('row', record)">删除</a>
          </template>
        </span>
      </s-table>

      <a-modal
        :visible="formVisible"
        :confirmLoading="confirmLoading"
        :title="modalFlag === 'create' ? '创建业务登记' : '编辑业务登记'"
        :width="560"
        @cancel="formVisible = false"
        @ok="handleSubmit"
      >
        <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
          <a-row :gutter="[20, 10]">
            <a-col :span="12">
              <a-form-model-item label="租户简称" prop="tenant">
                <a-select
                  showSearch
                  allowClear
                  placeholder="请选择客户名称"
                  ref="tenant_name"
                  v-model="form.tenant"
                  @select="handleTenantChange"
                  style="width: 100%"
                  :filter-option="filterOption"
                >
                  <a-select-option v-for="op in tenantOps" :key="op.id + '-' + op.address" :value="op.id">{{
                    op.name
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="租赁地址">
                <a-input v-model.trim="form.address" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="订单提醒时间">
                <a-date-picker v-model="form.remind_day" class="full-width"></a-date-picker>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="提前提醒天数">
                <a-input-number v-model="form.remind_days_before" class="full-width" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="租赁备注">
                <a-textarea v-model.trim="form.remark" />
              </a-form-model-item>
            </a-col>
            <a-divider orientation="left">费用信息</a-divider>
            <a-col :span="24">
              <edit-table :columns="feeColumns" :sourceData="feeTypes" @getTableDate="getFeeData"></edit-table>
            </a-col>
            <a-col :span="24" style="margin-top: 8px">
              <a-form-model-item label="应收备注">
                <a-textarea v-model.trim="form.charge_remark" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>
    </a-card>
  </keep-alive>
</template>

<script>
import { STable, EditTable } from '@/components'
import moment from 'moment'
import { getMmsInfoPage, getMmsRegisterTenantOptions, removeMmsInfo, saveMmsInfo } from '@/api/mms'
import { getCommonOptions } from '@/api/common'
import { filterOption } from '@/utils/util'
export default {
  components: {
    STable,
    EditTable
  },
  data() {
    return {
      queryParam: {},
      tenantOps: [],
      columns: [
        {
          title: '业务编号',
          dataIndex: 'serial_num'
        },
        {
          title: '租户简称',
          dataIndex: 'short_code'
        },
        {
          title: '租赁地址',
          dataIndex: 'address'
        },
        {
          title: '订单提醒时间',
          dataIndex: 'remind_day',
          width: 120,
          customRender: text => {
            return moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '提前提醒天数',
          dataIndex: 'remind_days_before'
        },
        {
          title: '租赁备注',
          dataIndex: 'remark'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ],
      feeColumns: [
        {
          title: '费用',
          dataIndex: 'fee_type_id',
          extra: {
            type: 'select',
            func: this.getCommonOptions,
            params: ['fee', { department: 3, type: 1 }],
            options: [],
            disabled: record => {
              return !(
                record.fee_status === 1 ||
                record.fee_status === 2 ||
                record.fee_status === 4 ||
                !record.fee_status
              )
            }
          }
        },
        {
          title: '金额',
          dataIndex: 'charge',
          width: 100,
          extra: {
            disabled: record => {
              return !(
                record.fee_status === 1 ||
                record.fee_status === 2 ||
                record.fee_status === 4 ||
                !record.fee_status
              )
            }
          }
        },
        {
          title: '发票号',
          dataIndex: 'invoice_num',
          width: 100,
          extra: {
            type: 'text'
          }
        },
        {
          title: '费用状态',
          dataIndex: 'fee_status',
          customRender: (text, record) => {
            if (this.GLOBAL.feeStatusMaps[text] && record.deny_reason) {
              return this.GLOBAL.feeStatusMaps[text].name + `:${record.deny_reason}`
            } else if (this.GLOBAL.feeStatusMaps[text]) {
              return this.GLOBAL.feeStatusMaps[text].name
            }
          }
        }
      ],
      selectedRowKeys: [],
      currentData: null,
      loadData: parameter => {
        return getMmsInfoPage('business_reg', Object.assign(parameter, this.queryParam)).then(res => {
          return res
        })
      },
      modalFlag: 'create',
      formVisible: false,
      confirmLoading: false,
      inputVisible: false,
      inputValue: '',
      form: {
        id: null,
        tenant: null,
        short_code: '',
        address: '',
        remind_day: null,
        remind_days_before: null,
        remark: '',
        rent_fees: [],
        charge_remark: ''
      },
      rules: {
        tenant: [{ required: true, message: '请选择租户简称', trigger: 'blur' }]
      },
      feeOpts: [],
      feeTypes: []
    }
  },
  created() {
    getMmsRegisterTenantOptions().then(v => {
      this.tenantOps = v
    });
  },
  methods: {
    moment,
    filterOption,
    getCommonOptions,
    getFeeData(data) {
      this.feeTypes = data
    },
    handleDelFeeType(index) {
      this.feeTypes.splice(index, 1)
    },
    handleTenantChange(val, p) {
      let vals = [null, '']
      if (val) {
        const key = p.data.key
        vals = key.split('-')
      }
      this.form['address'] = vals[1]
    },
    handleCreate() {
      this.modalFlag = 'create';
      this.formVisible = true;
      this.inputValue = '';
      this.inputVisible = false;
      this.form = {
        id: null,
        tenant: null,
        short_code: '',
        address: '',
        remind_day: null,
        remind_days_before: null,
        remark: '',
        rent_fees: [],
        charge_remark: ''
      };
      this.feeTypes = [];
      getCommonOptions('fee', { department: 3, type: 1 }).then(res => {
        res.forEach(fee => {
          if (fee.name === `租金` || fee.name === `电费` || fee.name === `水费`) {
            this.feeTypes.push({
              fee_type_id: fee.value,
              charge: null,
              invoice_num: ''
            });
          }
        });
      });
    },
    handleEdit(record) {
      this.modalFlag = 'update'
      this.formVisible = true
      this.inputValue = ''
      this.inputVisible = false
      record.rent_fees.forEach(f => {
        if (f.fee_status > 2 && f.fee_status !== 4) {
          f.disabled = true
        }
      })
      this.feeTypes = record.rent_fees
      record['remind_day'] = record['remind_day'] ? moment(record['remind_day'], 'YYYY/MM/DD') : null
      this.form = record
      this.$nextTick(_ => {
        this.$refs.form.clearValidate()
      })
    },
    handleDelete(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          let _ids = []
          if (flag === 'all') {
            _ids = this.selectedRowKeys
          } else {
            _ids = [record.id]
          }
          removeMmsInfo('business_reg', { ids: _ids }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.selectedRowKeys = []
            this.$refs.table.refresh(true)
          })
        }
      })
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const func = saveMmsInfo
          const data = { ...this.form }
          data['remind_day'] = data['remind_day'] ? moment('remind_day').valueOf() : ''
          data['short_code'] = this.$refs['tenant_name'].$el.innerText
          data['rent_fees'] = this.feeTypes.map(b => {
            const v = { ...b }
            v.fee_status = v.fee_status || null
            return v
          })
          func('business_reg', data)
            .then(_ => {
              this.confirmLoading = false
              this.formVisible = false
              this.$notification['success']({
                message: '提示',
                description: this.modalFlag === 'update' ? '编辑成功' : '创建成功.'
              })
              this.$refs.table.refresh(true)
            })
            .catch(_ => {
              this.formVisible = false
              this.confirmLoading = false
            })
        } else {
          return false
        }
      })
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    }
  }
}
</script>
